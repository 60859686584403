<template>
  <v-container fluid class="pa-0">
    <v-card tile flat max-width="1200" class="pa-0 mx-auto">
      <v-row no-gutters class="pt-12">
        <v-col md="6">
          <v-img class="d-none d-md-flex" min-width="100%" src="../../assets/images/bg_image.png"></v-img>
        </v-col>
        <v-col md="6" sm="12" cols="12" class="title">
          <v-list-item>
            <v-list-item-content class="justify-center align-self-start">公司Logo</v-list-item-content>
            <v-list-item-content class="align-end">
              <v-img :src="logoImg"></v-img>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="justify-center">公司名称</v-list-item-content>
            <v-list-item-content class="align-end">
              <v-text-field
                placeholder="公司名称"
                background-color="#EAF4FE"
                hide-details
                flat
                solo
                :value="companyInformation.firmname"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="justify-center">公司类型</v-list-item-content>
            <v-list-item-content class="align-end">
              <v-radio-group v-model="companyTypeGroup" row>
                <v-radio
                  v-for="(n,index) in companyType"
                  :key="index"
                  :label="n.typeName"
                  :value="n.type"
                ></v-radio>
              </v-radio-group>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="justify-center">公司地址</v-list-item-content>
            <v-list-item-content class="align-end">
              <v-text-field
                placeholder="公司地址"
                background-color="#EAF4FE"
                hide-details
                flat
                solo
                :value="companyInformation.location"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="justify-center align-self-start">公司简介</v-list-item-content>
            <v-list-item-content class="align-end">
              <v-textarea
                solo
                flat
                auto-grow
                background-color="#EAF4FE"
                name="input-7-4"
                label="Solo textarea"
                placeholder="公司简介"
                :value="companyInformation.content"
              ></v-textarea>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="justify-center">联系电话</v-list-item-content>
            <v-list-item-content class="align-end">
              <v-text-field
                placeholder="联系电话"
                background-color="#EAF4FE"
                hide-details
                flat
                solo
                :value="companyInformation.phone"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="justify-center">社会信用代码</v-list-item-content>
            <v-list-item-content class="align-end">
              <v-text-field
                placeholder="社会信用代码"
                background-color="#EAF4FE"
                hide-details
                flat
                solo
                :value="companyInformation.shehuicode"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="justify-center align-self-start">营业执照图片</v-list-item-content>
            <v-list-item-content class="align-end">
              <v-img :src="businessLicense"></v-img>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="justify-center align-self-start">公司形象图片</v-list-item-content>
            <v-list-item-content class="align-end">
              <v-img :src="companyImage"></v-img>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
let appUrl = 'https://api.ciplat.com';

export default {
  data: () => ({
    infonList: "",
    businessLicense: "",
    companyImage: "",
    logoImg: "",
    companyTypeGroup: 1,
    companyType: [
      { type: 1, typeName: "船舶公司" },
      { type: 2, typeName: "检验公司" }
    ],
    companyInformation: {}
  }),
  created() {
    this.info();
  },
  methods: {
    info() {
      //获取用户基础信息
      this.infonList = JSON.parse(localStorage.getItem("info"));
      // 获取管理员公司信息
      this.$server
        .getAdministratorMsg({ firmid: this.infonList.firmid })
        .then(data => {
          // 营业执照图片
          this.businessLicense = appUrl + data.data.img;
          // 公司logo
          this.logoImg = appUrl + data.data.logo;
          // 公司形象图片
          this.companyImage = appUrl + data.data.image;
          // 公司类型
          this.companyTypeGroup = parseInt(data.data.firmtype);
          // 公司信息
          this.companyInformation = data.data;
        });
    }
  }
};
</script>

<style>
</style>